<script>
import { useRoute } from 'vue-router/composables'
import SectionMenu from '/~/components/section-menu/section-menu.vue'
import { useProvider } from '/~/composables/provider'
export default {
  name: 'bill-payments-layout-view-desktop',
  components: {
    SectionMenu,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { isBillPaymentsTemplate } = useProvider()
    const route = useRoute()

    return {
      isBillPaymentsTemplate,
      route,
    }
  },
  computed: {
    isBillPaymentsV15Route() {
      return this.route.meta?.isBillPaymentsV15Route
    },
  },
}
</script>

<template>
  <div class="flex h-full">
    <div
      v-if="!isBillPaymentsTemplate"
      class="h-full w-72 flex-shrink-0 overflow-y-auto lg:w-80"
    >
      <div class="flex h-[72px] items-center px-6">
        <h2 class="flex-grow truncate pr-5 text-2xl">
          {{ heading }}
        </h2>
        <div class="shrink-0">
          <slot name="actions" />
        </div>
      </div>
      <div class="px-5">
        <slot name="description" />
      </div>
      <div v-if="menu.length" class="mx-5 mt-6">
        <section-menu :items="menu" />
      </div>
      <div class="mt-7 px-6">
        <slot name="content" />
      </div>
    </div>
    <div
      id="sidebar-content"
      class="relative h-full w-full overflow-y-auto overflow-x-hidden"
      :class="{
        'bg-white': !isBillPaymentsV15Route,
        'pt-7': isBillPaymentsV15Route,
      }"
    >
      <slot />
    </div>
  </div>
</template>
